import React from 'react'
import { BsLinkedin, BsInstagram } from 'react-icons/bs';
// import { FaFacebookF } from 'react-icons/fa';
const SocialMedia = () => {
  return (
    <div className="app__social">
      <div><a href="https://www.linkedin.com/in/shiva-karthik/" target="_blank" rel="noopener noreferrer"> <BsLinkedin /></a></div>
      <div><a href="https://www.instagram.com/shiva.karthik.y/?hl=en" target="_blank" rel="noopener noreferrer"><BsInstagram /></a></div>
    </div>
  )
}

export default SocialMedia